import * as React from "react"
import Layout from "../components/Layout";
import HomeSection from "../components/HomeSection";
import { SEO } from "../components/SEO";

const IndexPage = () => {
  return (
    <Layout color="blue" className="non-scrolling-page">
      <HomeSection />
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <SEO title="Sun Valley Wants You" description="Are you dreamer in search of the perfect getaway? Sun Valley, ID wants you."/>
)
