import React from "react";

const HomeSection = () => {

  return (<div className="home-screen">
    <div className="home-screen__row">
      <div className="home-screen__pannel home-screen__pannel--first background-blue">
        <p className="text-white">In the Valley, anything is possible.</p>
        <p className="text-red">Question is, which Sun Valley are you?</p>
      </div>
      <div className="home-screen__pannel background-yellow links-brown">
        <a href="/renegades">
          <div className="home-screen__image-area background-brown">
            <img className="home-screen__image" src="/Renegades.png" />
          </div>
          <h2 className="text-red">Renegades</h2>
        </a>
      </div>
      <div className="home-screen__pannel background-red links-white">
        <a href="/lovers">
          <div className="home-screen__image-area background-white">
            <img className="home-screen__image" src="/Lovers.png" />
          </div>
          <h2 className="text-brown">Lovers</h2>
        </a>
      </div>
    </div>
    <div className="home-screen__row">
      <div className="home-screen__pannel background-white links-yellow">
        <a href="/wanderers">
          <div className="home-screen__image-area background-yellow">
            <img className="home-screen__image" src="/Wanderers.png" />
          </div>
          <h2 className="text-blue">Wanderers</h2>
        </a>
      </div>
      <div className="home-screen__pannel background-blue links-red">
        <a href="yogis">
          <div className="home-screen__image-area background-red">
            <img className="home-screen__image" src="/Yogis.png" />
          </div>
          <h2 className="text-white">Yogis</h2>
        </a>
      </div>
      <div className="home-screen__pannel background-brown links-blue">
        <a href="/mamas-papas">
          <div className="home-screen__image-area background-blue">
            <img className="home-screen__image" src="/MamasPapas.png" />
          </div>
          <h2 className="text-yellow">Mamas + Papas</h2>
        </a>
      </div>
    </div>
  </div>)

}

export default HomeSection;
